var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "profile"
  }, [_c('div', {
    staticClass: "container"
  }, [_c('b-row', {
    staticClass: "mb-4"
  }, [_c('b-col', {
    attrs: {
      "md": "6"
    }
  }, [_vm.$options.components['profile-show-edit-profile'] ? [_c('profile-show-edit-profile')] : _vm._e()], 2), _c('b-col', {
    attrs: {
      "md": "6"
    }
  }, [_vm.$options.components['profile-view-all-address'] ? [_c('profile-view-all-address')] : _vm._e()], 2)], 1)], 1)]);
}
var staticRenderFns = []

export { render, staticRenderFns }