<template>
  <div class="profile">
    <div class="container">
      <b-row class="mb-4">
        <b-col md="6">
          <template v-if="$options.components['profile-show-edit-profile']">
            <profile-show-edit-profile />
          </template>
        </b-col>
        <b-col md="6">
          <template v-if="$options.components['profile-view-all-address']">
            <profile-view-all-address />
          </template>
        </b-col>
      </b-row>
    </div>
  </div>
</template>
<script>
export default {};
</script>
